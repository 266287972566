import * as React from 'react'
import Image from './image'

const HeroBanner = ({ imageSource, imageAlt, children }) => {
  return (
    <div className="relative flex items-center h-screen overflow-hidden">
      <Image
        src={imageSource}
        alt={imageAlt}
        className="fixed top-0 brightness-66 z-0 h-full w-full"
        imgClassName="object-cover object-left-25"
      />
      <div className="container relative text-white text-shadow text-center py-24">
        {children}
      </div>
    </div>
  )
}

export default HeroBanner